<template>
<div class="page" v-if="load">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="page" v-if="!load">
    <reservation-header
      :status="resData.id ? 'Saved' : 'New'"
      :title="'Predmet prodaje'"
      @save="saveData"
      @delete="deleteData"
      @saveAndLock="saveAndLock"
      @newNormal="createNormal"
      @newAdvance="createAdvance"
    ></reservation-header>
    <data-form2
      :key="resData['id'] + nextKey"
      :data="resData"
      :formFields="formFields"
      @changed="inputChanged"
      :readonly="true"
      minheight="1px"
      :nooverflow="true"
      @findFromRacChassis="findFromRacChassis"
      @findFromRac="findFromRac"
    />
    <form-futher :key="resData['id'] + 'foother'" v-if="resData" 
    :createdBy="resData['createdBy']"
    :created="resData['createdAt']"
    :updatedBy="resData['updatedBy']"
    :updated="resData['updatedAt']"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import FormFuther from "../users/FormFuther.vue";
import SellingsDataService from "../../services/sellings.service";
import ReservationHeader from "../reservation/header/ReservationHeader";
import ExchangeRatesDataService from "../../services/exchange-rates.service";
import utils from "../../utils/common";

export default {
  name: "session-form",
  props: [],
  components: {
    DataForm2,
    ReservationHeader,
    FormFuther,
  },
  data() {
    return {
      message: "",
      selling: null,
      nextKey: 0,
      load: false,
      damage: null,
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          // [
          //   {
          //     id: { type: "hidden" },
          //   },
          // ],
          [
            {
              id: { disabled: true, },
            },
            {
              createdAt: {
                type: "date",
                label: "Datum kreiranja ponude",
                disabled: false,
              },
            },
            {
              doc_nr: {
                type: "text",
                label: "Broj ponude",
                disabled: true,
              },
            },
            {
              delivery_time: {
                label: "Rok isporuke vozila (dan)",
                type: "number",
              },
            },
            {
              offer_validity: {
                label: "Rok važenja ponude (dan)",
                type: "number",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              buyer: {
                width: "610px",
                label: "Kupac vozila",
                type: "dropdown",
                url: "/customers?limit=100000&ascending=1&activeOnly=1",
              },
            },
            { ino_buyer: { label: "Ino Kupac", type: "checkbox" } },
            {
              purpose: {
                label: "Vozilo korišćeno u svrhu",
                type: "dropdown",
                url: "",
                list: [
                  { id: 1, name: "dugoročni poslovni najam" },
                  { id: 2, name: "rent a car" },
                ],
              },
            },
          ],
          [
            {
              exchange_date: {
                label: "Datum kursa",
                type: "date",
                max: new Date().toISOString().substring(0, 10),
              },
            },
            {
              exchange: {
                label: "Valuta",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "eur", name: "EUR" },
                  { id: "usd", name: "USD" },
                  { id: "aud", name: "AUD" },
                  { id: "chf", name: "CHF" },
                  { id: "gbp", name: "GBP" },
                  { id: "sek", name: "SEK" },
                  { id: "eur_adiko", name: "EUR Adiko" },
                  { id: "usd_adiko", name: "USD Adiko" },
                  { id: "aud_adiko", name: "AUD Adiko" },
                  { id: "chf_adiko", name: "CHF Adiko" },
                  { id: "gbp_adiko", name: "GBP Adiko" },
                  { id: "sek_adiko", name: "SEK Adiko" },
                ],
              },
            },
            // { val_date: { label: "Datum valute", type: "date" } },
            {
              exchange_value: {
                label: "Kurs",
                type: "number",
                description:
                  "Ukoliko kurs nije definisan za izabrani datum, možete ga preuzeti pomoću forme Kursna lista",
                key: "exc" + this.excKey,
              },
            },
            {
              damages: {
                label: "Totalna šteta",
                type: "checkbox",
              },
            }
          ],
          [
            {
              article_id: {
                width: "610px",
                label: "Predmet prodaje",
                type: "dropdown",
                url: "/articles/ext/forDoc/?in_sell=1",
              },
            },
            { bb_neto_eur: { label: "Neto prodajna cena (EUR)", type: "number" } },
            {
              bb_neto_rsd: {
                label: "Neto prodajna cena (RSD)",
                type: "number",
                disabled: true,
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            { chassis_nr: { label: "Broj šasije", type: "text", description:'Unesite broj šasije i selektujte taster Car Lista da bi preuzeli podatke' } },
            {
              getByCh: {
                type: "button",
                text: " Car Lista",
                emit: "findFromRacChassis",
                biIcon: "bi-car-front",
                btnColor: "btn-warning",
              },
            },
            { registration_nr: { label: "Broj registracije", type: "text", description:'Unesite broj registracije i selektujte taster Car Lista da bi preuzeli podatke' } },
            {
              getByReg: {
                type: "button",
                text: " Car Lista",
                emit: "findFromRac",
                biIcon: "bi-car-front",
                btnColor: "btn-warning",
              },
            },
          ],
          [
            { brand: { label: "Marka", type: "text" } },
            { model: { label: "Model", type: "text" } },
            { contract_nr: { label: "Broj ugovora", type: "text" } },
            { engine_nr: { label: "Broj motora", type: "text" } },
          ],
          [
            { year: { label: "Godina", type: "number" } },
            { km: { label: "Predjeno KM", type: "number" } },
            { engine_power: { label: "Snaga motora (KW)", type: "number" } },
            {
              engine_volume: { label: "Radna zapremina (ccv)", type: "number" },
            },
          ],
          [
            { seats: { label: "Broj sedista", type: "number" } },
            { color: { label: "Boja", type: "text" } },
            { mass: { label: "Masa (kg)", type: "number" } },
            {
              first_registration: {
                label: "Datum prve registracije",
                type: "date",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
    // dataSelling() {
    //   console.log({ data: this.$store.state.app.modalParams.data });
    //   return this.$store.state.app.modalParams.data;
    // },
    resData() {
      return this.selling ?? {};
    },
    requiredFields() {
      return [
        "buyer",
        "registration_nr",
        "exchange_value",
        "bb_neto_eur",
        "bb_neto_rsd",
        "article_id",
      ];
    },
  },
  methods: {
    async findFromRac(data) {
      if (!data.registration_nr) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Broj registracije nije validan",
        });
        return;
      }
      SellingsDataService.findFromRac(data.registration_nr)
        .then((response) => {
          if (response.data.length == 0) {
            this.$store.commit("app/showAlert", {
              type: "danger",
              message: "Nije pronađen nijedan rezultat",
            });
            return;
          } 
          this.setDataFromRac(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async findFromRacChassis(data) {
      if (!data.chassis_nr) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Broj šasije nije validan",
        });
        return;
      }
      SellingsDataService.findFromRacByChassis(data.chassis_nr)
        .then((response) => {
          if (response.data.length == 0) {
            this.$store.commit("app/showAlert", {
              type: "danger",
              message: "Nije pronađen nijedan rezultat",
            });
            return;
          } 
          this.setDataFromRac(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setDataFromRac(response) {
      utils.setDataFromRac(response, this.resData);
    },
    inputChanged(e, name) {
      this.resData[name] = e;

      if (name == "exchange") {
        this.getExchange(this.resData.exchange_date);
      }

      if (name == "exchange_date") {
        this.getExchange(e);
      }

      if (name == "bb_neto_eur") {
        this.resData["bb_neto_rsd"] = this.resData["exchange"]
            ? (this.resData["bb_neto_eur"] * this.resData["exchange_value"]).toFixed(2) : "0.00";
      }
      
    },
    getExchange(date) {
      ExchangeRatesDataService.getByDate(date).then((res) => {
        const exc = res.data[this.resData.exchange];
        this.resData.exchange_value = exc;
        this.resData["bb_neto_rsd"] = this.resData["exchange"]
            ? (this.resData["bb_neto_eur"] * this.resData["exchange_value"]).toFixed(2) : "0.00";
        // this.nextKey++;
      });
    },
    saveData() {
      // Validate
      if (
        !this.resData.buyer ||
        !this.resData.registration_nr ||
        !this.resData.exchange_value ||
        !this.resData.bb_neto_eur ||
        !this.resData.bb_neto_rsd ||
        !this.resData.article_id
      ) {
        let required = [];
        for (const temp of this.requiredFields) {
          if (!this.resData[temp]) required.push(temp);
        }
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Molimo vas da unesete sva obavezna polja! ${required}`,
        });
        return;
      }
      if (this.resData.registration_nr.length < 3) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Broj registracije mora imati bar 3 karaktera!`,
        });
        return;
      }
      if (this.resData && this.resData.id) {
        this.update(this.resData);
      } else {
        this.create(this.resData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      // console.log({ newDMG: newData.damages, dmg: this.damage })
      if (!!this.damage != !!newData.damages) {
        this.setForm(newData);
      } else {
        newData.sell_form = null;
      }
      SellingsDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      if (!newData["doc_nr"])
        newData["doc_nr"] = `PO-${newData["registration_nr"]
          .replaceAll("-", "")
          .substring(2)}-V-${new Date().toISOString().slice(0, 4)}`;
      this.setForm(newData);
      SellingsDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Prodaja je kreiran!",
          });
          this.$router.go(-1);
        })
        .catch((e) => {
          newData["doc_nr"] = null;
          this.selling.doc_nr = null;
          console.error(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: 'Greška prilikom kreiranja prodaje! Proverite da li je broj prodaje (registracija) već zauzet!',
          });
        });
    },
    setForm(newData) {
      const checkboxesValue = !newData['damages'];
        newData["sell_form"] = {
            doc_trafic_licence_handed_over: checkboxesValue,
            doc_service_booklet_handed_over: checkboxesValue,
            doc_insurance_handed_over: checkboxesValue,
            doc_spare_key_handed_over: checkboxesValue,
            engine_start: checkboxesValue,
            in_line_performace: checkboxesValue,
            gearbox_ok: checkboxesValue,
            brake_ok: checkboxesValue,
            parking_brake_ok: checkboxesValue,
            steering_ok: checkboxesValue,
            lighting_ok: checkboxesValue,
            siren_ok: checkboxesValue,
            radio_ok: checkboxesValue,
            air_conditioning_ok: checkboxesValue,
            heating_ok: checkboxesValue,
            cabin_fan_ok: checkboxesValue,
            cabin_light_ok: checkboxesValue,
            interior_mirror_ok: checkboxesValue,
            exretnal_mirror_ok: checkboxesValue,
            lighter_ok: checkboxesValue,
            seat_belts_ok: checkboxesValue,
            windows_ok: checkboxesValue,
            rear_shelf: checkboxesValue,
            air_bags_ok: checkboxesValue,
            wipers_ok: checkboxesValue,
            front_seats_adjustment_ok: checkboxesValue,
            rear_seats_adjustment_ok: checkboxesValue,
            front_seats_ok: checkboxesValue,
            rear_seats_ok: checkboxesValue,
            car_board_ok: checkboxesValue,
            other_doors_ok: checkboxesValue,
            hood_ok: checkboxesValue,
            alu_wheels_ok: checkboxesValue,
            antenna_ok: checkboxesValue,
            wiper_blades_ok: checkboxesValue,
            engine_ok: checkboxesValue,
            accumulator_ok: checkboxesValue,
            spare_tire_ok: checkboxesValue,
            wheel_wrench_ok: checkboxesValue,
            crane_ok: checkboxesValue,
            tire_repair_kit_ok: checkboxesValue,
            exterior_clean: checkboxesValue,
            interior_clean: checkboxesValue,
          };
    },
    deleteData() {
      if (this.resData["invoices"] && this.resData["invoices"].length > 0) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Postoje povezani racuni. Brisanje nije dozvoljeno!",
          });
          return;
      }
      utils.showDialog(
        this.resData,
        "Da li ste sigurni da zelite da obrisete prodaju?",
        "Brisanje prodaje: " + this.resData.id,
        () => {
          SellingsDataService.delete(this.resData.id)
            .then((response) => {
              this.$store.commit("app/showAlert", {
                type: "success",
                message: response.data.message,
              });
              this.$store.commit("app/hideModal");

              this.$router.go(-1);
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        }
      );
    },
  },
  async mounted() {
    this.load = true;
    if (this.$route.params.id != 0) {
      SellingsDataService.get(this.$route.params.id).then(async (res) => {
        this.selling = res.data;
        this.load = false;
        this.damage = this.selling.damages;
      });
    } else {
      this.selling = {
        exchange_date: new Date().toISOString().substring(0, 10),
        exchange: "eur",
        exchange_value: this.getExchange(new Date()),
      }
      this.load = false;
    }
  },
  created() {},
  watch: {},
};
</script>


<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
}
.company {
  font-size: smaller;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>